// Color scheme for light and dark modes.

// $light: #ff0066;
// $light: #3c3c3c;
$light: #4996dd;
$dark: #1ba77e;

// Highlighting color for code block borders and language name.

$code-light: #ff0066;
$code-dark: #d0f6db;

$message-green: #62bb47;
$message-yellow: #fcb827;
$message-orange: #f6821f;
$message-red: #e03940;
$message-purple: #953c96;
$message-blue: #009ddc;

$root-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$code-font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$root-font-size: 16px;
$root-line-height: 1.5;

$body-color: rgba(20,20, 20, 0.8);
$body-bg: rgba(20,20,20, 0.03);
$body-color-dark: hsla(0,0%,100%,.8);
$body-bg-dark: rgba(20,20,20,.9);

$date-color: rgba(70, 70, 70, 0.8);
$date-color-dark: rgba(200, 200, 200, 0.8);

$large-breakpoint: 38em;
$large-font-size: 20px;
