// Site structure

.container {
  max-width: 38rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.portrait {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border-radius: 3.5em;
  width: 7em;
  height: 7em;
}

footer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 90%;
}
