// Masthead
//
// The style of the site's title and optional secondary description.

.masthead {
  padding-top:    1rem;
  padding-bottom: 1rem;
  // margin-bottom: 2rem;
  text-align: center;
}

.masthead-title {
  margin-top: 0;
  margin-bottom: 20px;
  color: $body-color;
  position: relative;
  text-align: center;
  padding-right: 12px;
  
@media (prefers-color-scheme: dark) {
  color: $body-color-dark;
  }

  a {
    color: inherit;
  }

  small {
    font-size: 75%;
    font-weight: 400;
    opacity: .7;
  }
}

.navigation {
  display: block;
  font-size: 100%;
  font-weight: 300;
  color: $body-color;
  
@media (prefers-color-scheme: dark) {
  color: $body-color-dark;
  }
  
ul#navigation-list {
  list-style: none;
  margin: 0;
  padding: 0;
  }
  ul#navigation-list li {
    display: inline;
    list-style: none;
	padding-left: 0;
    padding-right: 12px;
	margin: 0;
    }
}
